import React, { useState, useEffect } from "react";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import LogoMonerisMerchant from "../SvgComponents/LogoMonerisMerchant";
import HeaderNavMenu from "../Library/HeaderNavMenu/index";
import { loginRequest, b2cPolicies } from "../../authConfig";
import { userActions, apiList } from "../../contextProviders/constant";
import { useAppStateProvider } from "../../contextProviders/AppStateProvider";
import { useIdleTimer } from "react-idle-timer";
import DateFormatter from "../../util/dateFormatter";
import { extractLanguageFromPathname } from "../../util/helper";
import pollApi from "../../contextProviders/poll-api";
import { env } from "../../cfg/env";
import "./index.scss";

const actionTypes = {
  edit_profile: "edit_profile",
  sign_out: "sign_out",
  lang: "lang",
};

const listLanguage = [
  {
    option: "English",
    Id: "en",
    type: "lang",
  },
  {
    option: "Français",
    Id: "fr",
    type: "lang",
  },
];

const getListAuth = (lang = "en") => {
  if (lang === "en")
    return [
      {
        option: "Settings",
        Id: actionTypes.edit_profile,
        type: "settings",
      },
      {
        option: "Logout",
        Id: actionTypes.sign_out,
        type: "logout",
      },
    ];
  return [
    {
      option: "Paramètres",
      Id: actionTypes.edit_profile,
      type: "settings",
    },
    {
      option: "Déconnexion",
      Id: actionTypes.sign_out,
      type: "logout",
    },
  ];
};

function Header({ lang, history }) {
  const [userName, setUserName] = useState("");
  const [isLoginError, setLoginError] = useState(false);
  const {
    appState = {},
    updateAppStateLocal,
    updateAppState,
  } = useAppStateProvider();
  const { instance, inProgress, accounts } = useMsal();
  const homeAccountId = accounts[0]?.homeAccountId ?? "";
  const logoutRequest = {
    account: instance.getAccountByHomeId(homeAccountId),
  };
  const isAuthenticated = useIsAuthenticated();
  const { profile = {} } = appState;
  const { firstName = "", lastName = "", userEmail = null } = profile;
  const language = window?.localStorage?.lang ?? "en";
  const [isSessionCheck, setSessionCheck] = useState(false);
  const searchParams = new URLSearchParams(window?.location?.search) ?? "";
  const logoutAction = searchParams.get("logout");
  const emailToRemember = searchParams?.get("email") ?? null;
  const sessioncheckFlag = searchParams?.get("sessioncheck");
  // const appCode = searchParams?.get("appCode") ?? "";
  const action = searchParams?.get("action") ?? "";
  const type = searchParams?.get("type") ?? "";
  const isActiveSession = window.sessionStorage.getItem("activeSession");
  const activeApp = window.localStorage.getItem("activeApp");
  const urlLang = extractLanguageFromPathname(window.location.pathname);
  const daysToPersist = 365;
  const expiry = DateFormatter.getDateinFuture(daysToPersist * 86400000);
  const domain = /local/.test(window.location.host)
    ? "localhost"
    : "moneris.com";
  let interval;

  const clearSsoMiddlewareSession = async () => {
    if (userEmail) {
      updateAppState({
        type: userActions.ssoSessionCheck,
        body: { email: userEmail },
      });
    }
  };

  useEffect(() => {
    if (action === "edit" && type === "reset_pass") {
      instance.loginRedirect(b2cPolicies.authorities.editPassword);
    }
  }, []);

  useEffect(() => {
    const name = `${firstName} ${lastName}`;
    setUserName(name);
  }, [firstName, lastName]);

  useEffect(async () => {
    if (userEmail && false) {
      await pollApi({
        payload: {
          url: `${apiList[userActions.ssoSessionCheck]}?email=${userEmail}`,
          method: "GET",
          body: {},
        },
        timeout: 1000 * 15,
        callback: () => {
          window.location = "?logout=true";
        },
        isAuthenticated,
        action: "set",
      });
    }
  }, [userEmail]);

  useEffect(() => {
    if (emailToRemember) {
      // 5 min in future
      const expiry_email = DateFormatter.getDateinFuture(1000 * 300);
      document.cookie = `email=${
        emailToRemember ?? ""
      }; expires=${expiry_email};domain=${domain};path=/`;
    }
  }, []);

  useEffect(async () => {
    if (
      isAuthenticated &&
      isActiveSession !== "true" &&
      activeApp === "mogo" &&
      userEmail
    ) {
      updateAppState({
        type: userActions.middlewareLogout,
        body: { email: userEmail },
        postCallHandler: async () => {
          await clearSsoMiddlewareSession();
          window.localStorage.removeItem("activeApp");
          document.cookie = `lang=${urlLang}; expires=${expiry};domain=${domain};path=/`;
          window.localStorage.setItem("lang", urlLang);
          instance.logoutRedirect(logoutRequest);
        },
      });
    }
  }, [isAuthenticated, userEmail]);

  useEffect(() => {
    if (sessioncheckFlag) {
      setSessionCheck(true);
    }
    if (action === "edit" && type === "sec_questions") {
      instance.loginRedirect(b2cPolicies.authorities.editSecurityQuestion);
    }
  }, [isAuthenticated && inProgress === InteractionStatus.None]);

  useEffect(() => {
    if (logoutAction === "true" && userEmail) {
      updateAppState({
        type: userActions.middlewareLogout,
        body: { email: userEmail },
        postCallHandler: async () => {
          await clearSsoMiddlewareSession();
          window.localStorage.removeItem("activeApp");
          document.cookie = `lang=${urlLang}; expires=${expiry};domain=${domain};path=/`;
          window.localStorage.setItem("lang", urlLang);
          instance.logoutRedirect(logoutRequest);
        },
      });
    }
  }, [userEmail]);

  const validateSession = (callback) => {
    const accessTokenRequest = {
      scopes: [`https://${env.sso.b2cTenantDomain}.onmicrosoft.com/sso/auth`],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;

        if (accessToken) callback(true);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              // Acquire token interactive success
              let accessToken = accessTokenResponse.accessToken;
              // Call your API with token
              if (accessToken) callback(true);
            })
            .catch(function (error) {
              // Acquire token interactive failure
              callback(false);
              console.log("invalid session", JSON.stringify(error));
            });
        } else {
          callback(false);
          console.log("invalid session", JSON.stringify(error));
        }
      });
  };

  const handlePostMessage = (flag = false) => {
    window.parent.postMessage({ is_user_active: flag }, "*");
    if (!flag && interval) clearInterval(interval);
  };

  useEffect(() => {
    if (isSessionCheck) {
      interval = setInterval(function () {
        validateSession(handlePostMessage);
      }, 60000);
    }
  }, [isSessionCheck]);

  const onIdle = () => {
    handlePostMessage(false);
    if (isSessionCheck) window.localStorage.clear();
    else {
      updateAppState({
        type: userActions.middlewareLogout,
        body: { email: userEmail },
        postCallHandler: async () => {
          await clearSsoMiddlewareSession();
          window.localStorage.removeItem("activeApp");
          document.cookie = `lang=${urlLang}; expires=${expiry};domain=${domain};path=/`;
          window.localStorage.setItem("lang", urlLang);
          instance.logoutRedirect(logoutRequest);
        },
      });
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 1800_000, //  30 inactivity
    throttle: 500,
  });

  const handleOptionSelected = (val) => {
    if (val.Id === actionTypes.edit_profile) {
      history.push(`/${lang}/setting`);
    }
    if (val.Id === actionTypes.sign_out) {
      updateAppState({
        type: userActions.middlewareLogout,
        body: { email: userEmail },
        postCallHandler: async () => {
          await clearSsoMiddlewareSession();
          window.localStorage.removeItem("activeApp");
          document.cookie = `lang=${urlLang}; expires=${expiry};domain=${domain};path=/`;
          window.localStorage.setItem("lang", urlLang);
          instance.logoutRedirect(logoutRequest);
        },
      });
    }
    if (val.type === actionTypes.lang) {
      const userSelectedLang = val?.Id ?? "en";
      window.localStorage.setItem("lang", userSelectedLang);
      document.cookie = `lang=${
        val?.Id ?? "en"
      }; expires=${expiry};domain=${domain};path=/`;
      let pathname = window.location.pathname;
      if (/\/en\//.test(pathname) && userSelectedLang !== "en") {
        pathname = pathname.replace("/en/", "/fr/");
        history.push(pathname);
      }
      if (/\/fr\//.test(pathname) && userSelectedLang !== "fr") {
        pathname = pathname.replace("/fr/", "/en/");
        history.push(pathname);
      }
    }

    return null;
  };

  const handleSingIn = () => {
    instance
      .loginRedirect(loginRequest)
      .then((resp) => console.log(resp))
      .catch((error) => {
        setLoginError(true);
        updateAppStateLocal({ isLoading: false, isError: true });
        console.log("login error:", JSON.stringify(error));
        instance.logoutRedirect(logoutRequest);
      });
  };

  if (
    !isAuthenticated &&
    inProgress === InteractionStatus.None &&
    !isLoginError &&
    !isSessionCheck &&
    action !== "reset_pass"
    // logoutAction !== "true"
  ) {
    handleSingIn();
  }

  return (
    <header className="monerisHeader">
      <div
        className="headerLeftContent"
        onClick={() => history.push(`/${lang}/home`)}
      >
        {/* <Link to={{ pathname: `/home` }} aria-label="Home"> */}
        <LogoMonerisMerchant width="110" />
        {/* </Link> */}
      </div>

      <div className="headerRightContent">
        <AuthenticatedTemplate>
          <HeaderNavMenu
            optionList={listLanguage}
            title={language === "en" ? "English" : "Français"}
            handleOptionSelected={handleOptionSelected}
          />
          <HeaderNavMenu
            optionList={getListAuth(lang)}
            title={userName}
            handleOptionSelected={handleOptionSelected}
          />
        </AuthenticatedTemplate>
      </div>
    </header>
  );
}

export default Header;
