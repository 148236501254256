/**
 * Attempt to remove /en|/fr from the front of the string if exists
 * @param {String} url
 * @returns {String}
 */
const removeLanguageFromUrl = (url) => {
  const match = url.match(/(?:\/(?:en|fr))([\w\-/\d?_]+)/i);
  return match?.[1] ?? url;
};

const extractLanguageFromPathname = (url) => {
  const match = url.match(/^\/(en|fr)(?=\/[\w\-/\d]+)?/i);
  return match?.[1]?.toLowerCase();
};

const capitaliseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const sortObjectByValue = (arr) => {
  // index value defines priority level
  if (Array.isArray(arr)) {
    const appCodeList = ["mogo", "md2"];
    return arr.sort(function (a, b) {
      const val1 = a?.app_code?.toLowerCase() ?? "";
      const val2 = b?.app_code?.toLowerCase() ?? "";
      if (
        appCodeList.indexOf(val1) === -1 &&
        appCodeList.indexOf(val2) === -1
      ) {
        if (val1 < val2) return -1;
        if (val1 > val2) return 1;
        return 0;
      } else {
        if (appCodeList?.indexOf(val1) < appCodeList?.indexOf(val2)) return 1;
        if (appCodeList?.indexOf(val1) > appCodeList?.indexOf(val2)) return -1;
        return 0;
      }
    });
  } else {
    return arr;
  }
};

// hash algo SHA256
function hashSha256(string) {
  const utf8 = new TextEncoder().encode(string);
  return crypto.subtle.digest("SHA-256", utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  });
}

function delay(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

// strip html caharacters from string
function stripHtmlTags (raw) {
  return raw ? raw.replace(/<[^>]*>/g, '') : '';
}

export {
  removeLanguageFromUrl,
  extractLanguageFromPathname,
  capitaliseFirstLetter,
  sortObjectByValue,
  hashSha256,
  delay,
  stripHtmlTags
};
