const partnerPortalUrls = {
  DEV: "https://www1-qa.moneris.com/{lang}/login?type=partner",
  QA: "https://www1-qa.moneris.com/{lang}/login?type=partner",
  PROD: "https://partners.moneris.com/{lang}/login?",
};

const md2Urls = (lang = "en") => {
  const url = {
    DEV: `https://www1-qa.moneris.com/${lang}/home`,
    QA: `https://www1-qa.moneris.com/${lang}/home`,
    PROD: `https://www2.moneris.com/${lang}/home`,
  };
  return url;
};

export { partnerPortalUrls, md2Urls };
